export const setAccessToken = (token) => ({
  type: "SET_ACCESS_TOKEN",
  payload: token,
});

export const clearAccessToken = () => ({
  type: "CLEAR_ACCESS_TOKEN",
});

export const loginSuccess = () => {
  return {
    type: "LOGIN_SUCCESS",
  };
};

export const logout = () => {
  return {
    type: "LOGOUT",
  };
};

export const setIdUser = (idUser) => ({
  type: "SET_ID_USER",
  payload: idUser,
});

export const clearIdUser = () => {
  return {
    type: "CLEAR_ID_USER",
  };
};
