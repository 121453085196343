import React, { useState, useEffect, useCallback, useRef } from "react";
import camuflado from "../../../camuflado.jpg";
import styled from "styled-components";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Col,
  Card,
  Row,
  Typography,
  Input,
  Form,
  Button,
  notification,
  Skeleton,
  Select,
  ConfigProvider,
  Modal,
} from "antd";
import moment from "moment";
import GraficoAnual from "../../graficos/GraficoAnual";
import GraficoTreino from "../../graficos/GraficoTreino";
import GraficoComp from "../../graficos/GraficoComp";
import { useHistory, useParams } from "react-router-dom";
import ptBR from "date-fns/locale/pt-BR"; // Importe o locale desejado
import CameraModal from "./CameraModal";
import InputMask from "react-input-mask";
import axiosInstance from "../../../axiosInterceptor";

const { Text } = Typography;
const { TextArea } = Input;

const FormularioHabitualidade = ({
  onTabChange,
  setarReload,
  recarregarFormUsuario,
}) => {
  const accessToken = window.localStorage.getItem("accessToken");
  const chave = window.localStorage.getItem("chaveCofre");
  const idUser = window.localStorage.getItem("idUser");
  const { idHabitualidadeEditar } = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [b64img, setB64img] = useState(null);
  const [b64imgNova, setB64imgNova] = useState(null);
  const [mostraBtn, setMostraBtn] = useState(false);
  const [nome, setNome] = useState("");
  const [obs, setObs] = useState("");
  const [cpf, setCpf] = useState("");
  const [cr, setCr] = useState("");
  const [numSerieEquipamento, setNumSerieEquipamento] = useState("");
  const [calibre, setCalibre] = useState("");
  const [qtdDisparos, setQtdDisparos] = useState("");
  const [imagem, setImagem] = useState("");
  const [tipoDaHabitualidade, setTipoDaHabitualidade] = useState("TREINAMENTO");
  const [dataDaHabitualidade, setDataDaHabitualidade] = useState("");
  const [dataDaHabitualidadeDatePicker, setDataDaHabitualidadeDatePicker] =
    useState(new Date());

  const [clubes, setClubes] = useState([]);
  const [selectedClube, setSelectedClube] = useState(null);
  const scrollViewRef = useRef(null);

  const history = useHistory();

  const handleClubeChange = (value) => {
    setSelectedClube(value);
  };

  const handleHabitualidadeChange = (value) => {
    setTipoDaHabitualidade(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `https://app-photo-api-java.herokuapp.com/v1/clube/usuario/${idUser}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (Array.isArray(response.data)) {
          setClubes(response.data);
          if (response.data.length > 0) {
            setSelectedClube(response.data[0].id);
          }
        }
      } catch (error) {
        console.error("Erro na requisição:", error);
      }
    };

    fetchData();
  }, [accessToken, idUser, recarregarFormUsuario]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `https://app-photo-api-java.herokuapp.com/v1/usuario/${idUser}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        setNome(response.data.nome);
        setCr(response.data.cr);

        setCpf(response.data.cpf);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Erro na requisição:", error);
      }
    };

    fetchData();
  }, [accessToken, idUser, recarregarFormUsuario]);

  const limpaImg = () => {
    setMostraBtn(false);
    setSelectedImage(null);
    setB64img(null);
    setB64imgNova();
  };
  function tornarAlfanumerico(str) {
    return str.replace(/[^a-zA-Z0-9]/g, "");
  }

  function tornarAlfanumericoComPonto(str) {
    return str.replace(/[^a-zA-Z0-9.]/g, "");
  }

  const onFinish = (data) => {
    data.idUsuario = idUser;
    setNome(data.nome);
    setCalibre(data.calibre);
    setQtdDisparos(data.qtdDisparos);
    setCr(data.cr);
    setCpf(tornarAlfanumerico(data.cpf));
    setNumSerieEquipamento(data.numSerieEquipamento);
    setObs(data.numSerieEquipamento);

    const data2 = {
      usuario: {
        id: idUser,
      },
      clube: {
        id: selectedClube,
      },
      nome: data.nome,
      data: dataDaHabitualidadeDatePicker,
      cpf: tornarAlfanumerico(data.cpf),
      cr: data.cr,
      obs: data.observacoes,
      num_serie_equipamento: data.numSerieEquipamento,
      calibre: data.calibre,
      qtd_disparos: data.qtdDisparos,
      imagem: b64img,
      tipoDaHabitualidade: tipoDaHabitualidade,
      chave: chave,
    };
    setLoading(true);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };

    axiosInstance
      .post(
        `https://app-photo-api-java.herokuapp.com/v1/habitualidade/nova`,
        data2,
        options
      )
      .then((response) => {
        setLoading(false);
        window.alert("Habitualidade salva com sucesso!");
        setarReload();
        limpaImg();
        setTimeout(() => {
          onTabChange("subTabHabitualidade1");
        }, 500);
      })
      .catch((error) => {
        console.error("Erro na requisição:", error);
        setLoading(false);
        window.alert("Falha no cadastro");
        setTimeout(() => {}, 300);
      })
      .finally(() => {});
  };

  const inputFileRef = useRef(null);
  const [selectedImagePreview, setSelectedImagePreview] = useState(null);

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Content = e.target.result.split(",")[1]; // Remove o cabeçalho
        setB64img(base64Content);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const marginTop = isMobile ? "90px" : "90px";
  const marginBottom = isMobile ? "90px" : "90px";
  const { Option } = Select;

  const validarCPF = (cpf) => {
    if (typeof cpf !== "string") return false;
    cpf = cpf.replace(/[^\d]+/g, "");
    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;
    let soma = 0;
    for (let i = 0; i < 9; i++) soma += parseInt(cpf.charAt(i)) * (10 - i);
    let resto = 11 - (soma % 11);
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.charAt(9))) return false;
    soma = 0;
    for (let i = 0; i < 10; i++) soma += parseInt(cpf.charAt(i)) * (11 - i);
    resto = 11 - (soma % 11);
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.charAt(10))) return false;
    return true;
  };

  return (
    <Container>
      <Row
        gutter={16}
        style={{
          justifyContent: "center",
          width: "100%",
          marginTop: marginTop,
          marginBottom: marginBottom,
        }}
      >
        <Col xs={24} lg={16}>
          <Card style={{ width: "100%" }}>
            <Row
              gutter={16}
              style={{
                justifyContent: "center",
              }}
            >
              {loading ? (
                <Skeleton active />
              ) : (
                <Form onFinish={onFinish}>
                  <Form.Item
                    label="Nome"
                    initialValue={nome}
                    name="nome"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, insira o nome!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item label="Data:">
                    <DatePicker
                      selected={dataDaHabitualidadeDatePicker}
                      onChange={(date) =>
                        setDataDaHabitualidadeDatePicker(date)
                      }
                      dateFormat="dd/MM/yyyy HH:mm"
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={30}
                      timeCaption="Hora"
                      locale={ptBR}
                    />
                  </Form.Item>

                  <Form.Item label="Clube:">
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Selecione um clube..."
                      value={selectedClube}
                      onChange={handleClubeChange}
                    >
                      {clubes.map((clube) => (
                        <Option key={clube.id} value={clube.id}>
                          {clube.nome}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Tipo da Habitualidade:">
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Selecione o tipo..."
                      value={tipoDaHabitualidade}
                      onChange={handleHabitualidadeChange}
                    >
                      <Option key={"TREINAMENTO"} value={"TREINAMENTO"}>
                        Treinamento
                      </Option>
                      <Option key={"COMPETIÇÃO"} value={"COMPETIÇÃO"}>
                        Competição
                      </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="CPF"
                    name="cpf"
                    initialValue={!isNaN(cpf) ? cpf : ""}
                    rules={[
                      {
                        required: true,
                        message: "Por favor, insira o cpf!",
                      },
                      {
                        validator: (_, value) => {
                          if (validarCPF(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject("CPF inválido");
                        },
                      },
                    ]}
                  >
                    <InputMask
                      mask="999.999.999-99"
                      value={cpf}
                      onChange={(e) => setCpf(e.target.value)}
                    >
                      {(inputProps) => <Input {...inputProps} />}
                    </InputMask>
                  </Form.Item>
                  <Form.Item
                    label="CR"
                    name="cr"
                    initialValue={cr !== "N/A" ? cr : ""}
                    rules={[
                      {
                        required: true,
                        message: "Por favor, insira o cr!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Número de Série do Equipamento"
                    name="numSerieEquipamento"
                    initialValue={numSerieEquipamento}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Calibre"
                    name="calibre"
                    initialValue={calibre}
                    rules={[
                      {
                        required: true,
                        message: "Por favor, insira o calibre!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Quantidade de disparos"
                    name="qtdDisparos"
                    initialValue={qtdDisparos}
                    rules={[
                      {
                        required: true,
                        message: "Por favor, insira a quantidade de disparos!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Obs" name="observacoes">
                    <TextArea
                      rows={4}
                      defaultValue={obs}
                      placeholder="Digite suas observações aqui..."
                    />
                  </Form.Item>
                  {mostraBtn ? (
                    <Form.Item>
                      <Button
                        type="primary"
                        style={{ backgroundColor: "black" }}
                        onClick={limpaImg}
                      >
                        LIMPAR IMAGEM
                      </Button>
                    </Form.Item>
                  ) : (
                    <div></div>
                  )}
                  {b64img ? (
                    <div
                      style={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Image
                        src={`data:image/png;base64,${b64img}`}
                        style={{ width: 200, height: 200 }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {chave ? (
                    <Button
                      type="primary"
                      style={{ backgroundColor: "black", width: "100%" }}
                      onClick={() => inputFileRef.current.click()}
                    >
                      ESCOLHER UMA IMAGEM DA GALERIA
                    </Button>
                  ) : (
                    ""
                  )}
                  <input
                    ref={inputFileRef}
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />

                  {selectedImagePreview && (
                    <div>
                      <img src={selectedImagePreview} alt="Selected" />
                    </div>
                  )}
                  {chave ? (
                    <CameraModal setImagem={setB64img}></CameraModal>
                  ) : (
                    ""
                  )}

                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    Salvar
                  </Button>
                </Form>
              )}
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: #484848;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-image: url(${camuflado});
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;
export default FormularioHabitualidade;
