import React, { useState, useEffect, useCallback } from "react";
import camuflado from "../../../camuflado.jpg";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import { AndroidOutlined, AppleOutlined } from "@ant-design/icons";
import {
  Col,
  Card,
  Row,
  Typography,
  Input,
  Form,
  Button,
  notification,
  Skeleton,
} from "antd";
import { format } from "date-fns";
import GraficoAnual from "../../graficos/GraficoAnual";
import GraficoTreino from "../../graficos/GraficoTreino";
import GraficoComp from "../../graficos/GraficoComp";
import axiosInstance from "../../../axiosInterceptor";

const { Text } = Typography;

const ClubeForm = ({ onTabChange, setarReload }) => {
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    values.idUsuario = idUser;

    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axiosInstance
      .post(
        "https://app-photo-api-java.herokuapp.com/v1/clube",
        values,
        options
      )
      .then((response) => {
        notification.success({
          message: "Sucesso",
          description: "Clube cadastrado com sucesso!",
        });
        setarReload();
        setTimeout(() => {
          onTabChange("subTabClube1");
        }, 1000);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Erro na requisição:", error);
        notification.error({
          message: "Falha",
          description: "Falha no cadastro.",
        });
      })
      .finally(() => {});
  };

  return (
    <Container>
      <Row
        gutter={16}
        style={{
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Col xs={20} lg={16}>
          <Card style={{ width: "100%" }}>
            <Row
              gutter={16}
              style={{
                justifyContent: "center",
              }}
            >
              {loading ? (
                <Skeleton active />
              ) : (
                <Form onFinish={onFinish}>
                  <Form.Item
                    label="CNPJ"
                    name="cnpj"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, insira o CNPJ!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Nome"
                    name="nome"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, insira o nome do clube!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Endereço"
                    name="endereco"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, insira o endereço!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Salvar
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: #484848;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-image: url(${camuflado});
`;

export default ClubeForm;
