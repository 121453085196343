import { BrowserRouter as Router, Route, Switch } from "react-router-dom"; // Importe BrowserRouter como Router

import { useMemo } from "react";

import { useSelector } from "react-redux";
import LoginScreen from "../LoginScreen";
import TopTab from "./views/TopTab";
import EditarClube from "./views/clube/EditarClube";
import EditarHabitualidade from "./views/habitualidades/EditarHabitualidade";
import Sair from "./views/Sair";

export default function Routes() {
  // window.localStorage.setItem("accessToken", "");
  const accessToken = window.localStorage.getItem("accessToken");

  return (
    <Router>
      <Switch>
        {accessToken ? (
          <>
            <Route path="/" exact component={TopTab} />
            <Route path="/:tab" exact component={TopTab} />
            <Route
              path="/editar-clube/:idClubeEditar"
              exact
              component={EditarClube}
            />
            <Route
              path="/editar-habitualidade/:idHabitualidadeEditar"
              exact
              component={EditarHabitualidade}
            />
            <Route path="/sair" exact component={Sair} />
          </>
        ) : (
          <Route path="/" component={LoginScreen} />
        )}
      </Switch>
    </Router>
  );
}
