import React from "react";
import "./PageHeader.css"; // Importe seu arquivo de estilos CSS

const PageHeader = ({ title }) => {
  return (
    <div className="page-header">
      <span className="text">{title}</span>
    </div>
  );
};

export default PageHeader;
