import React, { useState } from "react";
import Webcam from "react-webcam";
import { Button, Form, Modal } from "antd";
import "./CameraModal.css"; // Certifique-se de criar este arquivo CSS

const CameraModal = ({ setImagem }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [finalImage, setFinalImage] = useState(null);
  const webcamRef = React.useRef(null);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCapturedImage(null);
    setFinalImage(null);
  };

  const capturePhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
  };

  const confirmPhoto = () => {
    const base64Image = capturedImage.split(",")[1];
    setFinalImage(base64Image);
    setImagem(base64Image);
    closeModal();
  };

  return (
    <div>
      <Button
        type="primary"
        style={{ backgroundColor: "black", width: "100%" }}
        onClick={openModal}
      >
        TIRAR UMA FOTO
      </Button>

      <Modal
        visible={isModalOpen}
        onCancel={closeModal}
        footer={[
          <Button key="close" onClick={closeModal}>
            Fechar
          </Button>,
        ]}
      >
        <div className="modal-content">
          {!capturedImage && !finalImage && (
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              style={{ width: "100%", maxWidth: "100%" }}
            />
          )}
          {capturedImage && !finalImage && (
            <img src={capturedImage} alt="Captured" />
          )}
          {finalImage && <img src={finalImage} alt="Confirmed" />}
          <div className="buttons">
            {!capturedImage && !finalImage && (
              <button onClick={capturePhoto}>Capturar</button>
            )}
            {capturedImage && !finalImage && (
              <>
                <button onClick={confirmPhoto}>Confirmar</button>
                <button onClick={() => setCapturedImage(null)}>
                  Descartar
                </button>
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CameraModal;
