import React from "react";
import logo from "./logo.svg";
import LoginScreen from "./LoginScreen";
import Routes from "./app/routes";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Routes />
      </header>
    </div>
  );
}

export default App;
