import React, { useState, useEffect, useCallback } from "react";
import { Tabs } from "antd";

import {
  UserOutlined,
  HomeOutlined,
  ReadOutlined,
  TeamOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import camuflado from "../../camuflado.jpg";

import Home from "./Home.js/Home";
import styled from "styled-components";
import ClubeForm from "./clube/ClubeForm";
import CrudClube from "./clube/CrudClube";
import CrudHabitualidade from "./habitualidades/CrudHabitualidade";
import { Link, useHistory, useParams } from "react-router-dom";
import Sair from "./Sair";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faImage,
  faRightFromBracket,
  faShop,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import "./TabStyles.css";
import { logout } from "../../Token/actions";
import { useDispatch } from "react-redux";
import Perfil from "./perfil/Perfil";
import VisualizarPerfil from "./perfil/VisualizarPerfil";
import AlterarSenhaScreen from "./perfil/AlterarSenhaScreen";
import FormularioHabitualidade from "./habitualidades/FormularioHabitualidade";
import CameraModal from "./habitualidades/CameraModal";
import PageHeader from "./pageHeader/PageHeader";
const { TabPane } = Tabs;
const TopTab = () => {
  const history = useHistory();
  const [activeKey, setActiveKey] = useState("1");
  const { tab } = useParams();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("subTab1");
  const [activeTabPerfil, setActiveTabPerfil] = useState("subTab1");
  const [activeTabClube, setActiveTabClube] = useState("subTabClube1");
  const [tabName, setTabName] = useState("Início");
  const [recarregarDadosHabitualidades, setRecarregarDadosHabitualidades] =
    useState(false);
  const [recarregarDadosClube, setRecarregarDadosClube] = useState(false);

  const [recarregarDadosPerfil, setRecarregarDadosPerfil] = useState(false);
  const [recarregarDadosInicio, setRecarregarDadosInicio] = useState(false);

  const [activeTabHabitualidade, setActiveTabHabitualidade] = useState(
    "subTabHabitualidade1"
  );
  const [
    carregarUsuarioFormHabitualidade,
    setCarregarUsuarioFormHabitualidade,
  ] = useState(false);
  const [carregarPerfil, setCarregarPerfil] = useState(false);
  const [carregarClube, setCarregarClube] = useState(false);

  useEffect(() => {
    if (tab === "2" || tab === "4") {
      if (tab === "2") {
        setTabName("Habitualidade");
      }
      if (tab === "4") {
        setTabName("Clube");
      }

      setActiveKey(tab);
    }
  }, [tab]);

  const handleRecarregarDadosHabitualidades = () => {
    setRecarregarDadosHabitualidades(!recarregarDadosHabitualidades);
  };
  const handleRecarregarDadosPerfil = () => {
    setRecarregarDadosPerfil(!recarregarDadosPerfil);
  };

  const handleRecarregarDadosClube = () => {
    setRecarregarDadosClube(!recarregarDadosClube);
  };

  const handleButtonClick = () => {
    setActiveTab("subTab3");
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const handleTabChangePerfil = (key) => {
    setActiveTabPerfil(key);
    handleRecarregarDadosPerfil();
    if (key === "subTab2") {
      setCarregarPerfil(!carregarPerfil);
    }
  };
  const handleTabChangeClube = (key) => {
    setActiveTabClube(key);

    if (key === "subTabClube1") {
      handleRecarregarDadosClube();
    }
  };

  const handleTabChangeHabitualidade = (key) => {
    handleRecarregarDadosHabitualidades();
    setActiveTabHabitualidade(key);
    if (key === "subTabHabitualidade2") {
      setCarregarUsuarioFormHabitualidade(!carregarUsuarioFormHabitualidade);
    }
  };

  const handleTabClick = (key) => {
    if (key === "1") {
      setRecarregarDadosInicio(!recarregarDadosInicio);
      setTabName("Início");
    }

    if (key === "2") {
      handleTabChangeHabitualidade("subTabHabitualidade1");
      setTabName("Habitualidade");
    }
    if (key === "3") {
      setTabName("Perfil");
    }
    if (key === "4") {
      setTabName("Clube");
    }

    if (key === "5") {
      setTabName("Sair");
      const confirmed = window.confirm("Deseja sair?");
      if (confirmed) {
        window.localStorage.removeItem("accessToken");
        window.localStorage.removeItem("idUser");
        history.push("/");
        window.location.reload();
      } else {
        setActiveKey("1");
        setTabName("Início");
      }
    } else {
      setActiveKey(key);
    }
  };

  return (
    <Container>
      <PageHeader title={tabName} />
      <Tabs
        activeKey={activeKey}
        tabBarGutter={20}
        centered
        styled={{ width: "100%" }}
        onTabClick={handleTabClick}
        tabBarStyle={{
          backgroundColor: "black",
          color: "white",
          activeTab: "#43e97b",
          margin: 0,
          position: "fixed",
          zIndex: 99,
          width: "100%",
          bottom: 0,
        }}
      >
        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                icon={faHome}
                style={{ width: "25px", height: "25px" }}
              />
              Início
            </span>
          }
          key="1"
        >
          <Home recarregarDados={recarregarDadosInicio}></Home>
        </TabPane>

        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                icon={faImage}
                style={{ width: "25px", height: "25px" }}
              />
              Habitualidade
            </span>
          }
          key="2"
        >
          <Tabs
            activeKey={activeTabHabitualidade}
            tabBarGutter={20}
            centered
            styled={{ width: "100%" }}
            onTabClick={handleTabChangeHabitualidade}
            tabBarStyle={{
              backgroundColor: "black",
              color: "white",
              activeTab: "#43e97b",
              margin: 0,
              position: "fixed",
              width: "100%",
              top: "50px",
              zIndex: 99,
            }}
          >
            <TabPane tab="VISUALIZAR" key="subTabHabitualidade1">
              <CrudHabitualidade
                onTabChange={handleTabChangeHabitualidade}
                recarregarDados={recarregarDadosHabitualidades}
              ></CrudHabitualidade>
            </TabPane>
            <TabPane tab="REGISTRAR" key="subTabHabitualidade2">
              <FormularioHabitualidade
                onTabChange={handleTabChangeHabitualidade}
                setarReload={handleRecarregarDadosHabitualidades}
                recarregarFormUsuario={carregarUsuarioFormHabitualidade}
              ></FormularioHabitualidade>
            </TabPane>
          </Tabs>
        </TabPane>

        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                icon={faUser}
                style={{ width: "25px", height: "25px" }}
              />
              Perfil
            </span>
          }
          key="3"
        >
          <Tabs
            activeKey={activeTabPerfil}
            tabBarGutter={20}
            centered
            styled={{ width: "100%" }}
            onTabClick={handleTabChangePerfil}
            tabBarStyle={{
              backgroundColor: "black",
              color: "white",
              activeTab: "#43e97b",
              margin: 0,
              position: "fixed",
              width: "100%",
              top: "50px",
              zIndex: 99,
            }}
          >
            <TabPane tab="VISUALIZAR" key="subTab1">
              <VisualizarPerfil
                onTabChange={handleTabChangePerfil}
                recarregarDados={recarregarDadosPerfil}
              ></VisualizarPerfil>
            </TabPane>
            <TabPane tab="REGISTRO" key="subTab2">
              <Perfil
                onTabChange={handleTabChangePerfil}
                setarReload={handleRecarregarDadosPerfil}
                recarregarFormPerfil={carregarPerfil}
              ></Perfil>
            </TabPane>
            <TabPane tab="ALTERAR SENHA" key="subTab3">
              <AlterarSenhaScreen
                onTabChange={handleTabChangePerfil}
              ></AlterarSenhaScreen>
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                icon={faShop}
                style={{ width: "25px", height: "25px" }}
              />
              Clube
            </span>
          }
          key="4"
        >
          <Tabs
            activeKey={activeTabClube}
            tabBarGutter={20}
            centered
            styled={{ width: "100%" }}
            onTabClick={handleTabChangeClube}
            tabBarStyle={{
              backgroundColor: "black",
              color: "white",
              activeTab: "#43e97b",
              margin: 0,
              position: "fixed",
              width: "100%",
              top: "50px",
              zIndex: 99,
            }}
          >
            <TabPane tab="VISUALIZAR" key="subTabClube1">
              <CrudClube recarregarDados={recarregarDadosClube}></CrudClube>
            </TabPane>
            <TabPane tab="REGISTRO" key="subTabClube2">
              <ClubeForm
                onTabChange={handleTabChangeClube}
                setarReload={handleRecarregarDadosClube}
              ></ClubeForm>
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                icon={faRightFromBracket}
                style={{ width: "25px", height: "25px" }}
              />
              Sair
            </span>
          }
          key="5"
        ></TabPane>
      </Tabs>
    </Container>
  );
};
const Container = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const TabsContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  z-index: 1; /* Garante que as abas fiquem acima do conteúdo */
`;
export default TopTab;
